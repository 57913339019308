var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex flex-wrap justify-between ph1 pv3 link light-gray bg-adori-light-gray bb b--adori-gray mb relative",
      },
      [
        _c(
          "div",
          {
            staticClass: "w-20 flex justify-center items-center pointer",
            on: { click: _vm.openFlights },
          },
          [_vm._v("\n      " + _vm._s(_vm.campaign.name) + "\n    ")]
        ),
        _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
          _vm._v("\n      " + _vm._s(_vm.advertiser.name) + "\n    "),
        ]),
        _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
          _c("i", { staticClass: "f5 material-icons red inline mr2" }),
          _vm._v(
            "\n      " +
              _vm._s(_vm.mapCampaignKind(_vm.campaign.kind)) +
              "\n    "
          ),
        ]),
        _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                [_vm.campaign.startTime, _vm.campaign.endTime].includes(null)
                  ? "-"
                  : _vm.campaignDate(
                      _vm.campaign.startTime,
                      _vm.campaign.endTime
                    )
              ) +
              "\n    "
          ),
        ]),
        [_vm.campaign.startTime, _vm.campaign.endTime].includes(null)
          ? _c(
              "div",
              { staticClass: "w-10 flex justify-center items-center" },
              [_vm._v("\n      -\n    ")]
            )
          : _c("div", { staticClass: "w-10 flex justify-start items-center" }, [
              _c(
                "div",
                { staticClass: "flex flex-column" },
                [
                  _c("ProgressBar", {
                    attrs: {
                      options: _vm.options,
                      value: _vm.progress.barValue,
                      max: 100,
                    },
                  }),
                  _c("span", { staticClass: "f7" }, [
                    _vm._v(
                      _vm._s(_vm.progress.textValue) +
                        "/" +
                        _vm._s(_vm.progress.maxValue)
                    ),
                  ]),
                ],
                1
              ),
            ]),
        _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.campaign.totalFlights || 0) + "\n    "
          ),
        ]),
        _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
          _c(
            "button",
            {
              staticClass: "w-100 pv2 bn white br2 f6",
              class: {
                "bg-adori-red": _vm.campaign.status == _vm.filterConfig.ACTIVE,
                "bg-adori-very-light-gray": [
                  _vm.filterConfig.INACTIVE,
                  _vm.filterConfig.DISABLED,
                  _vm.filterConfig.ARCHIVED,
                ].includes(_vm.campaign.status),
                "bg-green": _vm.campaign.status == _vm.filterConfig.COMPLETE,
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.campaign.status) + "\n      ")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "w-5 flex justify-center items-center" },
          [
            _c("BasePopupMenu", {
              attrs: { icon: "more_vert", items: _vm.campaignMenu },
            }),
          ],
          1
        ),
        _vm.editCampaignStatus
          ? _c("ModalEditCampaign", {
              attrs: { campaign: _vm.campaign },
              on: { close: _vm.closeModal, update: _vm.update },
            })
          : _vm._e(),
        _vm.showModal
          ? _c("ModalCampaignShows", {
              attrs: { campaign: _vm.campaign },
              on: { close: _vm.closeShowModal },
            })
          : _vm._e(),
        _vm.modalConfirm
          ? _c("ModalConfirm", {
              attrs: { text: _vm.confirmText },
              on: { yes: _vm.yes, no: _vm.no },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }