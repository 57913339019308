












































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import TargetList from '@/views/campaign/components/TargetList.vue'
import TargetEpisodeList from '@/views/campaign/components/TargetEpisodeList.vue'
import { targetPayload, showsResponse } from '@/Interfaces/StoreInterFaces/campaignInterfaces/Campaign'
import LazyLoader from '@/mixins/LazyLoader'

import get from 'lodash.get'

import moment from 'moment'

@Component({
  components: {
    TargetList,
    TargetEpisodeList,
  },
})
export default class ModalCampaignShows extends Mixins(LazyLoader) {
  @Prop() campaign!: any

  @Getter networkId!: string
  @Getter campaignShows!: any
  @Getter rssFeedItemsByFeedUid!: any

  @Action getCampaignShows!: any
  @Action updateRssFeedItems!: any

  section = 'SHOW'

  loading = false

  payload: any = {
    feedUid: '',
    limit: 10,
    order: 'recent_first',
  }
  rssFeedUid = ''
  async mounted() {
    this.loading = true
    await this.getShows()
    this.loading = false
  }

  async getShows() {
    const payload = {
      networkId: this.networkId,
      campaignId: this.campaign.id,
      clear: false,
    }
    await this.getCampaignShows(payload)
  }

  get shows() {
    return get(this.campaignShows, 'data', []).map((obj: showsResponse) => {
      return {
        feedUid: obj.feed.uid,
        episodesAfterTime: obj.episodesAfterTime,
        episodesBeforeTime: obj.episodesBeforeTime,
        count: obj.targetedEpisodes,
      }
    })
  }

  get episodeList() {
    return get(this.rssFeedItemsByFeedUid[this.rssFeedUid], 'data', [])
  }

  async changeSection(id: string) {
    this.loading = true
    this.payload['rssFeedUid'] = id
    this.payload['offset'] = 0
    this.rssFeedUid = id
    delete this.payload['publishedAfter']
    delete this.payload['publishedBefore']
    const target = this.shows.find((obj: targetPayload) => obj.feedUid === id)
    if (target['episodesAfterTime']) this.payload['publishedAfter'] = moment(target['episodesAfterTime']).toISOString()
    if (target['episodesBeforeTime']) {
      this.payload['publishedBefore'] = moment(target['episodesBeforeTime']).toISOString()
    }
    this.section = 'EPISODE'
    this.hasItemsLeft = true
    this.increment = this.payload.limit
    this.resetIterations()
    await this.updateRssFeedItems(this.payload)
    this.loading = false
  }

  async hasItemsLeftLoad() {
    if (!this.isLoading()) {
      this.payload['offset'] = this.rssFeedItemsByFeedUid[this.rssFeedUid].data.length || 0
      await this.handleLoadMore(
        () => this.rssFeedItemsByFeedUid[this.rssFeedUid],
        this.updateRssFeedItems,
        this.payload
      )
    }
  }
}
