































































































































import { Component, Watch, Mixins } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import debounce from 'lodash.debounce'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import AddAudioAd from '@/components/Monetize/AddAudioAd.vue'
import TheCampaignList from '@/components/Campaigns/TheCampaignList.vue'
import { mixin as clickaway } from 'vue-clickaway'
import icons_campaign_icon_active from '@/assets/icons/campaign_icon_active.svg'
import { filter, modalType, filterPromoType } from '@/components/Campaigns/configs'
import get from 'lodash.get'

import campaign_ACTIVE from '@/assets/campaign/ACTIVE.svg'
import campaign_ALL from '@/assets/campaign/ALL.svg'
import campaign_ARCHIVED from '@/assets/campaign/ARCHIVED.svg'
import campaign_COMPLETE from '@/assets/campaign/COMPLETE.svg'
import campaign_DISABLED from '@/assets/campaign/DISABLED.svg'
import campaign_INACTIVE from '@/assets/campaign/INACTIVE.svg'

import { campaignQueryKeys, useGetAdvertiserList, useGetCampaigns } from '@/hooks/campaign'
import { reactive, SetupContext } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    AddAudioAd,
    TheCampaignList,
  },
  setup(_, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const { isFetching: isAdvertisersFetching } = useGetAdvertiserList(
      {
        limit: 100,
        offset: 0,
      },
      (data: any) => {
        root.$store.commit('setAllAdvertisers', data.data)
      }
    )
    useGetAdvertiserList(
      {
        limit: 100,
        offset: 0,
        kind: 'UNPAID',
      },
      (data: any) => {
        root.$store.commit('setSponsors', data.data)
      }
    )
    useGetAdvertiserList(
      {
        limit: 100,
        offset: 0,
        kind: 'PAID',
      },
      (data: any) => {
        root.$store.commit('setAdvertisers', data.data)
      }
    )

    const campaignParams = reactive({
      limit: 10,
      offset: 0,
      kind: undefined,
      status: undefined,
    })

    const { data: campaigns, isFetching: isCampaignFetching } = useGetCampaigns(campaignParams)
    return {
      queryClient,
      isAdvertisersFetching,
      campaignParams,
      campaigns,
      isCampaignFetching,
    }
  },
})
export default class ViewCampaign extends Mixins(clickaway) {
  @Action showAudioAddPlayer!: any

  currentTab: 'ALL' | 'ACTIVE' | 'ARCHIVED' | 'COMPLETE' | 'DISABLED' | 'INACTIVE' = 'ALL'
  dropDown = false

  pageNo = 1
  currentAudio!: any
  increment = 10
  timer: number = 0

  promoValue: string = 'ALL'

  queryClient!: QueryClient
  isAdvertisersFetching!: boolean
  campaigns!: any
  isCampaignFetching!: boolean
  campaignParams!: any

  get campaignData() {
    return get(this.campaigns, 'data', [])
  }

  get campaignCount() {
    return get(this.campaigns, 'count', 0)
  }
  get pageCount() {
    return Math.ceil(get(this.campaigns, 'count', 0) / 10)
  }

  get promoValueData() {
    return this.promoValue
  }

  get filterPromoType() {
    return filterPromoType
  }

  get filterConfig() {
    return filter
  }

  get modalType() {
    return modalType
  }

  get campaign_icon_active() {
    return icons_campaign_icon_active
  }

  get placHolderSrc() {
    const tabSrc = {
      ACTIVE: campaign_ACTIVE,
      ALL: campaign_ALL,
      ARCHIVED: campaign_ARCHIVED,
      COMPLETE: campaign_COMPLETE,
      DISABLED: campaign_DISABLED,
      INACTIVE: campaign_INACTIVE,
    }
    return tabSrc[this.currentTab]
  }

  @Watch('pageNo')
  watchPageNo() {
    this.campaignParams.offset = (this.pageNo - 1) * this.increment
  }

  async next() {
    if (this.pageNo + 1 <= this.pageCount) {
      this.pageNo += 1
    }
  }

  async prev() {
    if (this.pageNo > 1) {
      this.pageNo -= 1
    }
  }

  async OnPageNumberChanged(page: number) {
    if (this.timer) {
      window.clearTimeout(this.timer)
    }
    this.timer = window.setTimeout(async () => {
      if (page <= this.pageCount && page > 0) {
        this.pageNo = page
      }
    }, 600)
  }

  checkIfAll(value: string | undefined) {
    if (value === 'ALL') {
      return undefined
    }
    return value
  }

  handleTabChange(tab: any) {
    this.currentTab = tab
    this.campaignParams.status = this.checkIfAll(tab)
  }
  async changeFilterPromo() {
    this.campaignParams.kind = this.checkIfAll(this.promoValueData)
  }

  async handleAllTab() {
    this.queryClient.invalidateQueries(campaignQueryKeys.CAMPAIGN)
  }

  OpenDropdown() {
    this.dropDown = !this.dropDown
  }
  closeDropDown() {
    this.dropDown = false
  }

  async updateCampaign() {
    this.queryClient.invalidateQueries(campaignQueryKeys.CAMPAIGN)
  }

  debouncedSearch = debounce(() => {}, 600)

  openPaidCampaignModal() {
    this.$store.commit('setCurrentModal', modalType.PAID)
  }
  openUnpaidCampaignModal() {
    this.$store.commit('setCurrentModal', modalType.UNPAID)
  }
  openProgrammaticModal() {
    this.$store.commit('setCurrentModal', modalType.PROGRAMMATIC)
  }
}
