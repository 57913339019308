






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import ProgressBar from 'vuejs-progress-bar'
import moment from 'moment'
import { Action, Getter } from 'vuex-class'

// modals
import ModalEditCampaign from '@/components/Campaigns/Modals/ModalEditCampaign.vue'
import ModalConfirm from '@/components/Campaigns/Modals/ModalConfirm.vue'
import ModalCampaignShows from '@/components/Campaigns/Modals/ModalCampaignShows.vue'

import { filter } from '@/components/Campaigns/configs'

// common
import { getCampaignKind, options } from '@/components/Campaigns/campaigns'

@Component({
  components: {
    ProgressBar,
    ModalEditCampaign,
    ModalConfirm,
    ModalCampaignShows,
  },
})
export default class TheAdList extends Vue {
  @Prop() campaign!: any

  @Getter hasLoadedUser!: any
  @Getter networkId!: string
  @Getter advertiserById!: any

  @Action getOneAdvertiser!: any
  @Action updateCampaign!: any
  @Action deleteCampaign!: any

  selectedState!: boolean
  editCampaignStatus = false
  modalConfirm = false
  status = ''
  confirmText = ''
  showModal = false
  // advertiser!: any

  get progress() {
    const date1 = moment(this.campaign.startTime).subtract(1, 'seconds')
    const date2 = moment(this.campaign.endTime)
    const date = moment()
    const progressMax = date2.diff(date1, 'days')
    const progressVal = date.diff(date1, 'days')
    const max = 100 / progressMax

    return {
      textValue: progressVal < 0 ? 0 : progressVal > progressMax ? progressMax : progressVal,
      barValue: Math.round(max * progressVal),
      maxValue: progressMax,
    }
  }

  get filterConfig() {
    return filter
  }

  get options() {
    return options
  }

  openFlights() {
    this.$router.push({
      name: 'flight',
      params: { id: this.campaign.id, campaign: this.campaign },
    })
  }

  get campaignMenu() {
    const enable = {
      name: 'Enable',
      icon: 'visibility',
      onClick: async () => {
        this.confirmText = 'Are you sure, you want to enable?'
        this.status = this.filterConfig.ACTIVE
        this.modalConfirm = true
      },
    }
    const disable = {
      name: 'Disable',
      icon: 'visibility_off',
      onClick: async () => {
        this.confirmText = 'Are you sure, you want to disable?'
        this.status = this.filterConfig.DISABLED
        this.modalConfirm = true
      },
    }
    const viewFlights = {
      name: 'View Flights',
      icon: 'launch',
      onClick: () => {
        this.$router.push({
          name: 'flight',
          params: { id: this.campaign.id, campaign: this.campaign },
        })
      },
    }

    const edit = {
      name: 'Edit',
      icon: 'edit',
      onClick: () => {
        this.editCampaignStatus = true
      },
    }

    const archive = {
      name: 'Archive',
      icon: 'archive',
      onClick: () => {
        this.confirmText = 'Are you sure, you want to archive? It cannot be enabled again and campaign stops serving'
        this.status = this.filterConfig.ARCHIVED
        this.modalConfirm = true
      },
    }

    const preview = {
      name: 'Preview',
      icon: 'visibility',
      onClick: () => {
        this.showModal = true
      },
    }

    const report = {
      name: 'Report',
      icon: 'bar_chart',
      onClick: () => {
        // this.$router.push(`/campaign/report/${this.campaign.id}`)
        this.$router.push({
          name: 'report',
          params: { id: this.campaign.id, campaign: this.campaign },
        })
      },
    }
    if (this.campaign.status === this.filterConfig.DISABLED) {
      return [viewFlights, edit, enable, archive, report]
    } else if (this.campaign.status === this.filterConfig.INACTIVE) {
      return [viewFlights, edit, archive, report]
    } else if (this.campaign.status === this.filterConfig.ACTIVE) {
      return [viewFlights, edit, disable, archive, report, preview]
    } else if (this.campaign.status === this.filterConfig.ARCHIVED) {
      return [viewFlights]
    } else {
      return [viewFlights, archive, report]
    }
  }

  async yes() {
    if (this.status === this.filterConfig.ARCHIVED) {
      const payload = {
        networkId: this.networkId,
        campaignId: this.campaign.id,
      }
      this.modalConfirm = false
      await this.deleteCampaign(payload)
      this.$emit('update')
      return
    }
    const payload = {
      data: {
        status: this.status,
      },
      networkId: this.networkId,
      campaignId: this.campaign.id,
    }

    this.modalConfirm = false
    await this.updateCampaign(payload)
    this.$emit('update')
  }

  no() {
    this.modalConfirm = false
  }

  get advertiser() {
    return this.advertiserById(this.campaign.advertiserId)
  }

  mapCampaignKind(kind: string) {
    return getCampaignKind(kind)
  }

  campaignDate(startDate: string, endDate: string) {
    let date1 = moment(startDate)
    let date2 = moment(endDate)

    return `${date1.format('DDMMM')}-${date2.format('DDMMM')} ${date2.diff(date1, 'days') + 1} day(s)`
  }

  closeModal() {
    this.editCampaignStatus = false
  }

  closeShowModal() {
    this.showModal = false
  }

  update() {
    this.$emit('update')
  }
}
