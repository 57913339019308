var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray fw5 f3" }, [
          _vm._v(_vm._s(_vm.campaign.name)),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "flex justify-center min-w7 overflow-x-hidden" },
              [
                _c("div", {
                  staticClass: "loader loader-inline overflow-x-hidden",
                }),
              ]
            )
          : _c("div", { staticClass: "w-100 f5 pv4 white min-w7" }, [
              _vm.section === "SHOW"
                ? _c(
                    "div",
                    { staticClass: "scroll custom-height scrollbar w-100 ph2" },
                    _vm._l(_vm.shows, function (target, index) {
                      return _c("TargetList", {
                        key: index,
                        attrs: { target: target, view: true },
                        on: { section: _vm.changeSection },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.shows.length === 0
                ? _c(
                    "div",
                    { staticClass: "white flex flex-column items-center" },
                    [
                      _c("img", {
                        staticClass: "grpImage mt3",
                        attrs: {
                          src: require("@/assets/Group121.svg"),
                          alt: "group",
                        },
                      }),
                      _c("div", { staticClass: "mt4" }, [
                        _vm._v("Oops.. This campaign doesn't have any shows"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.section === "EPISODE"
                ? _c(
                    "div",
                    {
                      staticClass: "flex items-center pointer",
                      on: {
                        click: function ($event) {
                          _vm.section = "SHOW"
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons f3 white link pointer pointer",
                        },
                        [_vm._v("arrow_back")]
                      ),
                      _vm._v("back\n      "),
                    ]
                  )
                : _vm._e(),
              _vm.section === "EPISODE"
                ? _c(
                    "div",
                    { staticClass: "mt3 custom-height scrollbar" },
                    [
                      _vm._l(_vm.episodeList, function (episode, index) {
                        return _c("TargetEpisodeList", {
                          key: index,
                          attrs: { episode: episode },
                        })
                      }),
                      _vm.hasItemsLeft
                        ? _c(
                            "div",
                            { staticClass: "tc pv3 ph4 bt b--adori-gray bw1" },
                            [
                              _c("BaseButtonLoadMore", {
                                attrs: {
                                  rootBottomMargin: "800",
                                  text: "Load more",
                                  onClick: _vm.hasItemsLeftLoad,
                                  onVisible: _vm.hasItemsLeftLoad,
                                  loading: _vm.isLoading(),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }