var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [
          _vm._v("Update Campaign"),
        ]),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c("div", { staticClass: "flex flex-column w-100 min-w7" }, [
            _c("div", { staticClass: "flex justify-between w-100 white mt3" }, [
              _c("div", { staticClass: "light-gray w-50" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("BaseLabel", {
                      staticClass: "mb2 w-30",
                      attrs: { for: "Name", text: "Name" },
                    }),
                  ],
                  1
                ),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.campaignName,
                        expression: "campaignName",
                      },
                    ],
                    staticClass:
                      "bg-adori-very-light-gray br2 ph3 bw0 pv2 f6 white w-80",
                    attrs: { placeholder: "Sponsor Name" },
                    domProps: { value: _vm.campaignName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.campaignName = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "light-gray w-50" }),
            ]),
            _c(
              "div",
              {
                staticClass: "flex justify-between items-center white mt3",
                class: _vm.campaignforeverOption ? "w-30" : "w-90",
              },
              [
                _c("BaseLabel", {
                  staticClass: "mb2",
                  attrs: { for: "campaignName", text: "Campaign Run Date" },
                }),
                _c("BaseCheckbox", {
                  attrs: {
                    text: "Forever",
                    initialState: _vm.campaignforeverOption,
                  },
                  on: { toggle: _vm.toggleCheckBox },
                }),
                !_vm.campaignforeverOption
                  ? _c("div", { staticClass: "flex flex-column items-start" }, [
                      _c("span", { staticClass: "flex" }, [
                        _c(
                          "div",
                          [
                            _c("BaseLabel", {
                              staticClass: "mb1",
                              attrs: {
                                for: "campaignName",
                                text: "Start Date",
                              },
                            }),
                            _c("date-picker", {
                              staticClass: "adori-date-picker w-45 mr3",
                              attrs: {
                                lang: "en",
                                type: "date",
                                format: "YYYY-MM-DD",
                                "not-before": _vm.notBeforeStartDate,
                                "not-after": _vm.notAfterStartDate,
                                placeholder: "Enter Start Date",
                              },
                              on: { input: _vm.validateDate },
                              model: {
                                value: _vm.campaignStartDate,
                                callback: function ($$v) {
                                  _vm.campaignStartDate = $$v
                                },
                                expression: "campaignStartDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("BaseLabel", {
                              staticClass: "mb1",
                              attrs: { for: "campaignName", text: "End Date" },
                            }),
                            _c("date-picker", {
                              staticClass: "adori-date-picker w-45",
                              attrs: {
                                lang: "en",
                                type: "date",
                                format: "YYYY-MM-DD",
                                "not-before": _vm.notBeforeEndDate,
                                "not-after": _vm.notAfterEndtDate,
                                placeholder: "Enter End Date",
                              },
                              on: { input: _vm.validateDate },
                              model: {
                                value: _vm.campaignEndDate,
                                callback: function ($$v) {
                                  _vm.campaignEndDate = $$v
                                },
                                expression: "campaignEndDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.validationObj.date
                        ? _c("div", { staticClass: "f6 adori-red" }, [
                            _vm._v("Both Start/End Dates are required"),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex justify-end items-center w-90 white mt6-l" },
            [
              _c(
                "div",
                { staticClass: "mt3 mb2 flex justify-between mr4" },
                [
                  _c("BaseButtonBorder", {
                    staticClass: "bw0 bg-adori-light-gray fr",
                    attrs: {
                      text: "Close",
                      onClick: function () {
                        _vm.$emit("close")
                      },
                    },
                  }),
                  _c("BaseButtonRed", {
                    staticClass: "fl ml2",
                    attrs: {
                      text: "Update Campaign",
                      onClick: _vm.handleUpdateCampaign,
                    },
                  }),
                  _vm.isUploadingTag
                    ? _c("div", { staticClass: "fl loader loader-inline mt2" })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.modalConfirm
            ? _c("ModalConfirm", {
                attrs: {
                  text: "Updating a campaign might affect the dates within all your flights.\nAre you sure you want to continue?",
                },
                on: { yes: _vm.yes, no: _vm.no },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }