var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c("div", { staticClass: "limiter scrollbar mb5" }, [
            _c(
              "div",
              { staticClass: "flex justify-between items-center w-100" },
              [
                _c("TheTitle", {
                  attrs: {
                    icon: "dvr",
                    title: "Campaign",
                    type: "img",
                    svgIcon: _vm.campaign_icon_active,
                  },
                }),
                _vm.campaignCount !== 0
                  ? _c("div", [
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.closeDropDown,
                                expression: "closeDropDown",
                              },
                            ],
                            staticClass:
                              "button bn f6 flex items-center bg-adori-red pv2 ph3 br2 pointer",
                            attrs: { for: "menu-checkbox" },
                            on: { click: _vm.OpenDropdown },
                          },
                          [
                            _c("span", { staticClass: "normal white" }, [
                              _vm._v("Create Campaign"),
                            ]),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "f5 material-icons white inline ml2",
                              },
                              [_vm._v("expand_more")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.dropDown,
                                expression: "dropDown",
                              },
                            ],
                            staticClass:
                              "menu flex flex-column absolute white bn br2 w-100 bg-white",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "black link flex justify-center pv3 pointer",
                                on: { click: _vm.openPaidCampaignModal },
                              },
                              [_vm._v("Paid Campaign")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "black link flex justify-center pv3 pointer",
                                on: { click: _vm.openUnpaidCampaignModal },
                              },
                              [_vm._v("Unpaid Campaign")]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex justify-between items-center w-100 mb3" },
              [
                _c("div", { staticClass: "flex relative items-center" }, [
                  _c("div", { staticClass: "f5 silver mr2" }, [
                    _vm._v("Filter by"),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.promoValue,
                          expression: "promoValue",
                        },
                      ],
                      staticClass:
                        "bg-adori-light-gray input-reset bn br2 pa2 pr2 white shadow-1 db arrow w4",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.promoValue = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.changeFilterPromo,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "ALL" } }, [
                        _vm._v("All"),
                      ]),
                      _c(
                        "option",
                        { domProps: { value: _vm.filterPromoType.PAID_PROMO } },
                        [_vm._v("Paid Promo")]
                      ),
                      _c(
                        "option",
                        {
                          domProps: { value: _vm.filterPromoType.UNPAID_PROMO },
                        },
                        [_vm._v("Unpaid Promo")]
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex items-center silver" },
                  _vm._l(_vm.filterConfig, function (filter) {
                    return _c(
                      "span",
                      {
                        staticClass: "ph3 pv2 pointer ttc",
                        class: {
                          "bb b--red":
                            _vm.currentTab === _vm.filterConfig[filter],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleTabChange(_vm.filterConfig[filter])
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.filterConfig[filter].toLocaleLowerCase())
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm.isCampaignFetching || _vm.isAdvertisersFetching
              ? _c(
                  "div",
                  _vm._l(new Array(11), function (_, index) {
                    return _c("div", {
                      key: index,
                      staticClass:
                        "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size mb2",
                    })
                  }),
                  0
                )
              : _c("div", [
                  _vm.campaignData.length
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-wrap justify-between ph1 pv3 link light-gray bg-adori-light-gray bb b--adori-gray br2 relative",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "w-20 flex justify-center" },
                                [_vm._v("Name")]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-10 justify-center" },
                                [
                                  _c("div", [_vm._v("Advertiser /")]),
                                  _c("div", [_vm._v("Sponsorer")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-10 flex justify-center" },
                                [_vm._v("Type")]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-10 flex justify-center" },
                                [_vm._v("Dates")]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-10 flex justify-center" },
                                [_vm._v("Progress")]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-10 flex justify-center" },
                                [_vm._v("Flight(s)")]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-10 flex justify-center" },
                                [_vm._v("Status")]
                              ),
                              _c("div", {
                                staticClass: "w-5 flex justify-center",
                              }),
                            ]
                          ),
                          _vm._l(_vm.campaignData, function (campaign, index) {
                            return _c("TheCampaignList", {
                              key: index,
                              attrs: { campaign: campaign },
                              on: { update: _vm.updateCampaign },
                            })
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-end pv3 ph4 bt bg-adori-light-gray b--adori-gray bw1",
                            },
                            [
                              _c("BaseButtonRed", {
                                staticClass: "mr3 youtube-link",
                                attrs: {
                                  text: "Prev",
                                  onClick: _vm.prev,
                                  disabled: _vm.pageNo === 1,
                                },
                              }),
                              _c("input", {
                                staticClass:
                                  "bg-adori-light-gray adori-gray br2 w-38",
                                attrs: { type: "text" },
                                domProps: { value: _vm.pageNo },
                                on: {
                                  input: function ($event) {
                                    _vm.OnPageNumberChanged(
                                      parseInt($event.target.value)
                                    )
                                  },
                                },
                              }),
                              _c("span", { staticClass: "pa2 silver" }, [
                                _vm._v("/ " + _vm._s(_vm.pageCount)),
                              ]),
                              _c("BaseButtonRed", {
                                staticClass: "ml2 youtube-link",
                                attrs: {
                                  text: "Next",
                                  onClick: _vm.next,
                                  disabled: _vm.pageNo === _vm.pageCount,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.campaignData.length === 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bg-adori-light-gray br2 ba b--adori-gray light-gray vh-75 flex justify-center items-center column",
                        },
                        [
                          _c("div", [
                            _c("p", { staticClass: "f3 mb5" }, [
                              _vm._v("Create Campaign"),
                            ]),
                          ]),
                          _c("img", {
                            attrs: {
                              src: _vm.placHolderSrc,
                              alt: "",
                              width: "200",
                              height: "200",
                            },
                          }),
                          _c("div", { staticClass: "shadow" }),
                          _c("div", { staticClass: "mb3" }, [
                            _vm._v("Oops.. no campaign yet! create one"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex justify-around w-40" },
                            [
                              _c("BaseButtonRed", {
                                staticClass: "ph4 mt3 mr1 h2",
                                attrs: {
                                  text: "Paid Campaign",
                                  onClick: _vm.openPaidCampaignModal,
                                },
                              }),
                              _c("BaseButtonRed", {
                                staticClass: "ph4 mt3 mr1 h2",
                                attrs: {
                                  text: "Unpaid Campaign",
                                  onClick: _vm.openUnpaidCampaignModal,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }