


























































































import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ModalConfirm from '@/components/Campaigns/Modals/ModalConfirm.vue'

@Component({
  components: {
    ModalConfirm,
  },
})
export default class ModalEditCampaign extends Vue {
  @Prop() campaign!: any
  @Getter networkId!: string

  @Action updateCampaign!: any

  campaignName = ''
  isUploadingTag = false
  campaignforeverOption = false
  campaignStartDate = ''
  campaignEndDate = ''
  notBeforeStartDate: any = moment().utc().toISOString()

  notAfterStartDate: any = null
  notBeforeEndDate: any = moment().utc().toISOString()
  notAfterEndtDate: any = null

  validationObj = {
    date: false,
  }

  modalConfirm = false

  created() {
    this.campaignName = this.campaign.name
    if (![this.campaign.startTime, this.campaign.endTime].includes(null)) {
      this.campaignStartDate = this.campaign.startTime
      this.campaignEndDate = this.campaign.endTime
    } else this.campaignforeverOption = true
  }

  toggleCheckBox() {
    this.campaignforeverOption = !this.campaignforeverOption
  }

  validateDate() {
    this.notAfterStartDate = this.campaignEndDate
    if (['', null].includes(this.campaignStartDate) && !['', null].includes(this.campaignEndDate)) {
      this.campaignStartDate = moment().utc().toISOString()
    }
    if (!['', null].includes(this.campaignStartDate)) this.notBeforeEndDate = this.campaignStartDate
    else {
      this.notBeforeEndDate = moment().utc().toISOString()
    }

    if (this.campaignforeverOption) {
      this.validationObj.date = false
      return false
    } else {
      if (['', null].includes(this.campaignStartDate) || ['', null].includes(this.campaignEndDate)) {
        this.validationObj.date = true
        return true
      } else {
        this.validationObj.date = false
        return false
      }
    }
  }

  async yes() {
    this.modalConfirm = false
    try {
      this.isUploadingTag = true
      let payload: any = {
        data: {},
        networkId: this.networkId,
        campaignId: this.campaign.id,
      }
      if (this.campaignName !== '') payload.data['name'] = this.campaignName
      if (this.campaignforeverOption === true) {
        payload.data['startTime'] = moment().startOf('day').utc().toISOString()
        payload.data['endTime'] = null
      } else {
        payload.data['startTime'] = moment(this.campaignStartDate).startOf('day').utc().toISOString()
        payload.data['endTime'] = moment(this.campaignEndDate).endOf('day').utc().toISOString()
      }

      await this.updateCampaign(payload)
      this.isUploadingTag = false
      this.$emit('update')
    } catch (error) {
      this.isUploadingTag = false
      this.$emit('update')
    }
  }

  no() {
    this.modalConfirm = false
  }

  handleUpdateCampaign() {
    if (this.validateDate()) return
    this.modalConfirm = true
  }
}
